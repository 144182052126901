import { z } from "zod";
import { definitions } from "./generated-schema";
import { accountPlan, paidFeatures } from "server/auth/types";

export type OauthTokenExchangeRequest =
  definitions["OauthTokenExchangeRequest"];
export type OauthTokenExchangeResponse = Pick<
  definitions["OauthTokenExchangeResponse"],
  "jwts"
>;

export const JwtSchema = z.object({
  jwt: z.string(),
  account_id: z.string(),
});
export type Jwt = z.infer<typeof JwtSchema>;
export const JwtResponseSchema = z.object({
  jwts: z.array(JwtSchema),
});

export const OauthTokenResponseSchema = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  expires_in: z.number(),
  token_type: z.literal("Bearer"),
  scope: z.string(),
  zapier_internal: z
    .object({
      zap_session: z.string(),
      csrf_token: z.string(),
      sso_hint: z.string(),
    })
    .optional(),
});
export type OauthTokenResponse = z.infer<typeof OauthTokenResponseSchema>;

export type SessionTokenExchangeRequest =
  definitions["SessionTokenExchangeRequest"];
export type SessionTokenExchangeResponse = Pick<
  definitions["SessionTokenExchangeResponse"],
  "jwt"
>;

export const AccountOverrideSchema = z.object({
  id: z.string(),
  accountId: z.number(),
  paidFeatureAccess: z.array(paidFeatures).default([]),
  plan: accountPlan.nullable().default(null),
  projectLimit: z.number().nullable().default(null),
});

export const AccountEntitlementsSchema = z.object({
  plan_status: z.record(z.string(), z.any()).optional(),
  limits: z.record(z.string(), z.any()),
  quotas: z.record(z.string(), z.any()).optional(),
  overrides: AccountOverrideSchema.optional(),
});

// TODO The Catalog team needs to update the OpenAPI spec for AccountEntitlementsResponse
// See this thread: https://zapier.slack.com/archives/C04A6RGP6SD/p1702058478798839
// The following type is a temporary workaround.
export type AccountEntitlementsResponse = z.infer<
  typeof AccountEntitlementsSchema
>;

// TODO Uncomment the below code when the Catalog team updates the OpenAPI spec for AccountEntitlementsResponse
// export type AccountEntitlementsResponse = ReturnType<
//   typeof assertTypesCompatible<
//     z.infer<typeof AccountEntitlementsSchema>,
//     operations["api_v4_accounts_entitlements"]["responses"][200]["schema"]
//   >
// >;
