import "zod-openapi/extend";

import { z } from "zod";
import { MinimalUserSchema, UserSchema } from "./user";
import {
  ALPHANUMERIC_DASHES_SPACES_REGEX,
  PROJECT_SLUG_REGEX,
  PROJECT_SLUG_CASE_INSENSITIVE_REGEX,
  COLOR_HEX_OR_RGB_REGEX,
  GOOGLE_ANALYTICS_TRACKING_ID_REGEX,
} from "utils/regex";
import { ProjectAuthType, StripeConnectedAccountStatus } from "@prisma/client";
import { PageSchema, navigationLinksSchema } from "./pages";
import {
  CustomizableThemeColorName,
  customizableThemeColorNames,
} from "lib/theme/themes/app-theme";
import { TableWithFieldsSchema } from "./table-tables";
import { ZapOutputSchema } from "./zaps";
import { paidFeatures } from "server/auth/types";

export const colorSchema = z.string().regex(COLOR_HEX_OR_RGB_REGEX).optional();

export const projectAppearanceColorName = z.enum(
  customizableThemeColorNames as [
    CustomizableThemeColorName,
    ...CustomizableThemeColorName[],
  ]
);

export const FullProjectAppearanceSchema = z.record(
  projectAppearanceColorName,
  colorSchema
);
export type FullProjectAppearance = z.infer<typeof FullProjectAppearanceSchema>;

export const ProjectAppearanceSchema = z.nullable(FullProjectAppearanceSchema);

export type ProjectAppearance = z.infer<typeof ProjectAppearanceSchema>;

export const UploadcareImageSchema = z.object({
  name: z.string(),
  url: z.string(),
});

export type UploadcareImage = z.infer<typeof UploadcareImageSchema>;

export const NullableUploadcareImageSchema = z.nullable(UploadcareImageSchema);

export const ProjectLogoSchema = z.nullable(
  z.object({
    name: z.string(),
    url: z.string().url(),
    showLogoOnLoginPage: z.boolean(),
    height: z.number(),
  })
);

export type ProjectLogo = z.infer<typeof ProjectLogoSchema>;

export const projectNameSchema = z
  .string()
  .min(1, "Cannot be empty")
  .max(32)
  .regex(
    ALPHANUMERIC_DASHES_SPACES_REGEX,
    "Can only contain letters, dashes, and spaces"
  );

export const projectSlugSchema = z
  .string()
  .regex(
    PROJECT_SLUG_REGEX,
    "Can only contain letters, numbers, and single dashes"
  );

export const projectSlugCaseInsensitiveSchema = z
  .string()
  .regex(
    PROJECT_SLUG_CASE_INSENSITIVE_REGEX,
    "Can only contain letters, numbers, and single dashes"
  );

export const ProjectSchema = z.object({
  id: z.string().cuid(),
  name: z.string(),
  slug: z.string(),
  customDomain: z.string().toLowerCase().nullable(),
  homepageId: z.string().cuid().nullable(),
  appearance: ProjectAppearanceSchema,
  hideZapierButton: z.boolean(),
  googleAnalyticsTrackingId: z.string().nullable(),
  logo: ProjectLogoSchema,
  favicon: NullableUploadcareImageSchema,
  stytchOrganizationId: z.string().nullable(),
  creatorId: z.string().cuid(),
  accountId: z.number(),
  globalNavigation: z.boolean().nullable(),
  includeLogo: z.boolean().nullable(),
  linkedPageIds: z.array(z.string().cuid()).nullable(),
  navigationLinks: navigationLinksSchema,
  authType: z.nativeEnum(ProjectAuthType),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isHidden: z.boolean(),
});

export const ProjectStripeConnectedAccountSchema = z.discriminatedUnion(
  "status",
  [
    z.object({
      status: z.literal(StripeConnectedAccountStatus.Enabled),
      id: z.string(),
    }),
    z.object({
      status: z.literal(StripeConnectedAccountStatus.NeedsMoreInfo),
      id: z.string(),
    }),
    z.object({
      status: z.literal(StripeConnectedAccountStatus.PendingVerification),
      id: z.null(),
    }),
    z.object({
      status: z.literal(StripeConnectedAccountStatus.Disabled),
      id: z.null(),
    }),
  ]
);

export type ProjectStripeConnectedAccount = z.infer<
  typeof ProjectStripeConnectedAccountSchema
>;

export const ProjectWithExtraSchema = ProjectSchema.extend({
  creator: UserSchema.extend({
    zapierId: z.number(),
  }),
  homepage: z
    .object({
      name: z.string(),
      slug: z.string(),
    })
    .nullable()
    .optional(),
  requiresAuth: z.boolean(),
  authType: z.nativeEnum(ProjectAuthType),
  captchaEnabled: z.boolean().default(false).openapi({ effectType: "input" }),
  _count: z
    .object({
      pages: z.number(),
    })
    .optional(),
  paidFeatureAccess: z.array(paidFeatures).optional(),
  stripeConnectedAccount: ProjectStripeConnectedAccountSchema.default({
    status: StripeConnectedAccountStatus.Disabled,
    id: null,
  }).openapi({ effectType: "input" }),
});

export type ParsedProject = z.infer<typeof ProjectSchema>;

export const ProjectWithCreatorSchema = ProjectSchema.extend({
  creatorId: ProjectSchema.shape.creatorId.optional(),
  creator: UserSchema,
});

export const paginatedListProjectsSchema = z.object({
  accountId: z.number().optional(),
  query: z.string().optional(),
  limit: z.number().min(1).max(100).nullish(),
  cursor: z.string().cuid().nullish(),
  sortBy: z
    .object({
      key: z.enum(["updatedAt", "name"]),
      order: z.enum(["asc", "desc"]),
    })
    .nullish(),
  sharing: z.enum(["all", "shared", "my"]).nullish(),
});

export const updateProjectSchema = z.object({
  id: z.string().cuid(),
  name: projectNameSchema.optional(),
  slug: projectSlugSchema.optional(),
  homepageId: z.string().cuid().nullable().optional(),
  appearance: ProjectAppearanceSchema.optional(),
  hideZapierButton: z.boolean().optional(),
  favicon: UploadcareImageSchema.optional(),
  googleAnalyticsTrackingId: z
    .string()
    .regex(GOOGLE_ANALYTICS_TRACKING_ID_REGEX)
    .nullable()
    .optional(),
  globalNavigation: z.boolean().optional(),
  includeLogo: z.boolean().optional(),
  navigationLinks: navigationLinksSchema.optional(),
});

export const checkProjectDomainSchema = z.object({
  projectId: z.string().cuid(),
});

export const createProjectDomainSchema = z.object({
  projectId: z.string().cuid(),
  domain: z.string().toLowerCase(),
});

export const deleteProjectDomainSchema = z.object({
  projectId: z.string().cuid(),
});

export const projectPublicSchema = z.object({
  id: z.string().cuid(),
  name: z.string(),
  accountId: z.number(),
  appearance: ProjectAppearanceSchema,
  pageCount: z.number(),
});

export const InterfacesProjectSchema = ProjectSchema.extend({
  pages: z.record(z.string(), PageSchema),
}).openapi({ ref: "InterfacesProject" });
export type InterfacesProject = z.infer<typeof InterfacesProjectSchema>;

export const ProjectCreateOutputSchema = z.object({
  project: InterfacesProjectSchema,
  creator: UserSchema,
  tables: z.record(TableWithFieldsSchema),
  zaps: z.record(z.string(), ZapOutputSchema),
  resourceMap: z.record(z.string(), z.string()),
});
export type ProjectCreateOutput = z.infer<typeof ProjectCreateOutputSchema>;

// For published pages
// -----------------------------------------------------------------------------

export const projectForPublishedPageSchema = ProjectWithExtraSchema.extend({
  creator: MinimalUserSchema,
  createdAt: z
    .date()
    .transform((val) => val.toISOString())
    .pipe(z.string().datetime()),
  updatedAt: z
    .date()
    .transform((val) => val.toISOString())
    .pipe(z.string().datetime()),
});

export const projectForPublishedLoginPageSchema = ProjectWithExtraSchema.pick({
  id: true,
  name: true,
  appearance: true,
  logo: true,
  authType: true,
  captchaEnabled: true,
  paidFeatureAccess: true,
  stytchOrganizationId: true,
}).extend({ creator: MinimalUserSchema });

export const setAuthSchema = z.object({
  projectId: z.string().cuid(),
  auth: z.union([
    z.object({ type: z.literal(ProjectAuthType.None) }),
    z.object({
      type: z.literal(ProjectAuthType.Password),
      password: z.string().min(8).max(128),
    }),
    z.object({
      type: z.literal(ProjectAuthType.Consumer),
    }),
    z
      .object({
        type: z.literal(ProjectAuthType.Stytch),
        magicLinkEnabled: z.boolean().optional(),
        googleSignInEnabled: z.boolean().optional(),
      })
      .refine(
        ({ googleSignInEnabled, magicLinkEnabled }) => {
          return googleSignInEnabled != null || magicLinkEnabled != null;
        },
        { message: "At least one authorization method must be set" }
      ),
  ]),
  captchaEnabled: z.boolean().default(false).openapi({ effectType: "input" }),
});
